/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, createRef, useRef } from "react";


import { useHistory, useParams } from 'react-router-dom';
import App2PdfGridstack from "components/Templates/App2PdfGridstack";
import { getCurrentVariableSpaceFromStep } from 'components/Templates/steps/StepVariableSpaceResolver';
import { DesignTimeVariableSpace } from "components/Templates/steps/DesignTimeVariableSpace";
import ReactMediaLibraryWrapper from "../../components/MediaGallery/ReactMediaLabraryWrapper"


// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  Input,
  Form,
  FormGroup,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import firebaseApp from "firebaseConfig";

import ClipLoader from "react-spinners/ClipLoader";

import ContextualModal from "components/Modal"
import { css } from "@emotion/core";
import ChoosableTemplateElements from "components/Templates/ChoosableTemplateElements";


import { v4 as uuidv4 } from 'uuid';
import TemplateSteps from "../../components/Templates/steps/TemplateSteps";

const style = { width: "100%", marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingTop: "30px", paddingBottom: "20px", backgroundColor: "#d3d3d3", borderRadius: "10px" };

const TemplateDesigner = () => {

  const { templateId } = useParams(); //This comes from the route
  const history = useHistory();

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showSaveAsDefaultTemplateButton, setShowSaveAsDefaultTemplateButton] = useState(false);

  const [variableSpace, setVariableSpace] = useState(new DesignTimeVariableSpace(""));

  let [loading, setLoading] = useState(false);

  //const [items, setItems] = useState([])

  const loadingCss = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

  const [modalOpen, setModalOpen] = useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  const [currentModalItem, setCurrentModalItem] = useState({ elementType: "", textValue: "", pdfPosition: "" });
  const setModalElement = (currentModalElement) => setCurrentModalItem(currentModalElement);
  const [isMediaShown, setIsMediaShown] = useState(false);

  const [template, setTemplate] = useState({
    templateName: "New template",
    createdAt: Date.now(),
    numberedImages: "NoNumberedImages",
    languageCode: "se",
    steps: [],
    mainGrid: {
      elements: []
    },
    pageHeader: {
      headerVisibility: "Show on all pages",
      elements: []
    },
    pageFooter: {
      footerVisibility: "Show on all pages",
      elements: []
    }
  })

  var currentSubControls = [];

  const updateSubControls = (item, add) => {

    const listControlsCopy = [...currentSubControls];

    if (add) {

      listControlsCopy.push(item);

      currentSubControls = listControlsCopy;

      return listControlsCopy;
    } else {

      let filteredControl = listControlsCopy.filter(x => x.id !== item.id);

      currentSubControls = filteredControl;

      return filteredControl;
    }
  }

  const staticImageSelected = (image, staticImageControl) => {
    if (staticImageControl.element.elementType === "StaticImage") {
      staticImageControl.element.imagePath = image.thumbnailUrl;
      toggle();
      setShowSaveButton(true);
    }
  }

  const deleteFromGrid = (items, itemId) => {

    var indexToUpdate = 0;

    items.map((val, index) => {
      if (val.id === itemId) {
        indexToUpdate = index;
      }
    });

    var removed = items.splice(indexToUpdate, 1);

    //console.log("Deleting item from grid: " + JSON.stringify(removed));
  }

  const deleteGridItem = (itemId) => {

    //For now this is an item in the main grid
    let items = [...template.mainGrid.elements];

    deleteFromGrid(items, itemId);

    updateTemplate("mainGrid.elements", items);
  }

  const deleteHeaderGridItem = (itemId) => {

    //For now this is an item in the main grid
    let items = [...template.pageHeader.elements];

    deleteFromGrid(items, itemId);

    updateTemplate("pageHeader.elements", items);
  }

  const deleteFooterGridItem = (itemId) => {

    //For now this is an item in the main grid
    let items = [...template.pageFooter.elements];

    deleteFromGrid(items, itemId);

    updateTemplate("pageFooter.elements", items);
  }

  const updateHeightAndPosition = (items, changedItems) => {
    if (items.length === 0) {
      //console.log("Items are empty...");
      return;
    }

    let newItems = [];
    items.map((item, index) => {

      newItems.push(item);

      changedItems.map((changed, changedIndex) => {

        if (item.id === changed.el.id) {
          let toEdit = newItems[index];

          toEdit.element.width = changed.w;
          toEdit.element.height = changed.h;
          toEdit.element.posX = changed.x;
          toEdit.element.posY = changed.y;

          newItems[index].element = toEdit.element;
        }
      })
    });

    items = newItems;

    //console.log("Updated " + changedItems.length + " items.");

    setShowSaveButton(true);

    return items;
  }

  const updateElementWidhtHeightAndPosition = (changedItems) => {

    let items = [...template.mainGrid.elements];
    updateHeightAndPosition(items, changedItems);
  }

  const updateHeaderElementWidhtHeightAndPosition = (changedItems) => {

    let items = [...template.pageHeader.elements];

    updateHeightAndPosition(items, changedItems);
  }

  const updateFooterlementWidhtHeightAndPosition = (changedItems) => {

    let items = [...template.pageFooter.elements];

    updateHeightAndPosition(items, changedItems);
  }

  const updateElements = (item, id) => {

    var headerElements = [...template.pageHeader.elements];
    var mainElements = [...template.mainGrid.elements];
    var footerElements = [...template.pageFooter.elements];

    //Check if it´s in header we shoud update
    headerElements.map((val, index) => {
      if (val.id === id) {

        headerElements[index].element = item;

        updateTemplate("template.pageHeader.elements", headerElements);

        toggle();

        return;
      }
    });

    mainElements.map((val, index) => {

      if (val.id === id) {

        mainElements[index].element = item;

        updateTemplate("template.mainGrid.elements", mainElements);

        toggle();

        return;
      }
    });

    footerElements.map((val, index) => {
      if (val.id === id) {

        footerElements[index].element = item;

        updateTemplate("template.pageFooter.elements", footerElements);

        toggle();

        return;
      }
    });

  }

  const updateTemplate = (stateName, newValue) => {

    let toUpdate = Object.assign({}, template);

    if (stateName === "pageHeader.headerVisibility") {
      toUpdate.pageHeader.headerVisibility = newValue;
    } else if (stateName === "pageHeader.elements") {
      toUpdate.pageHeader.elements = newValue;
    } else if (stateName === "pageFooter.footerVisibility") {
      toUpdate.pageFooter.footerVisibility = newValue;
    } else if (stateName === "pageFooter.elements") {
      toUpdate.pageFooter.elements = newValue;
    } else if (stateName === "mainGrid.elements") {
      toUpdate.mainGrid.elements = newValue;
    } else if (stateName === "steps") {

      //Update global variable space
      mapAndSetVariableSpace(newValue);

      toUpdate[stateName] = newValue;
    }
    else {
      toUpdate[stateName] = newValue;
    }

    setTemplate(toUpdate);

    //Set this when something has changed in the template
    setShowSaveButton(true);
  }

  const loadLocalFile = async (localFilePath) => {
    var imageBlob = await fetch(localFilePath)
      .then((response) => response.blob())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error(error);
      });

    return imageBlob;
  }

  async function uploadTaskPromise(blob, fileName) {
    return new Promise(function (resolve, reject) {

      var storageRef = firebaseApp.storage().ref();

      const user = JSON.parse(localStorage.getItem('user'));

      var metadata = {
        contentType: 'image/jpeg',
      };

      var imageRef = storageRef.child("templateImages").child(user.uid).child(fileName);

      imageRef.getDownloadURL()
        .then(url => {
          alert("Filen finns redan, vill du använda en ny, byt namn och ladda upp igen.");
          resolve(url);
        })
        .catch(error => {
          const uploadTask = imageRef.put(blob, metadata);

          uploadTask.on('state_changed',
            function (snapshot) {
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              //console.log('Upload is ' + progress + '% done')
            },
            function error(err) {
              //console.log('error', err)
              reject()
            },
            function complete() {
              uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log("Upploaded url: " + downloadURL);
                resolve(downloadURL)
              })
            }
          )
        });

    })
  }

  const uploadFileToFirebase = async (localFilePath, fileName) => {

    var blob = await loadLocalFile(localFilePath);

    const storageUrl = await uploadTaskPromise(blob, fileName);
    //console.log(storageUrl)

    return storageUrl;
  }

  const uploadFilesIfLocal = async (element) => {
    //Check if the image is a local file, in that case upload it
    if (element.imagePath.startsWith("blob:")) {
      console.log("Uploading image file");
      element.imagePath = await uploadFileToFirebase(element.imagePath, element.fileName);

    } if (element.imagePath.startsWith("/static")) {
      //Make sure that a static file from our server never gets sent to the app
      element.imagePath = "";
    }

    return element;
  }

  const getPureElements = async (elements) => {

    var pureElements = await Promise.all(elements.map(async (element) => {
      var pureElement = element.element;

      if (pureElement.elementType === "StaticImage")
        pureElement = await uploadFilesIfLocal(pureElement);

      if (pureElement.elementType === "ElementGroup") {
        pureElement.elements = await getPureElements(pureElement.elements);
      }

      return pureElement;

    }));

    return pureElements;
  }

  const mapToSavableTemplate = async () => {

    var elementsJson = JSON.parse(JSON.stringify(template.mainGrid.elements));

    var mainTemplateElements = await getPureElements(elementsJson);

    var headerElements = await getPureElements(template.pageHeader.elements);

    var footerElements = await getPureElements(template.pageFooter.elements);

    let toSave = {
      templateName: template.templateName,
      createdAt: template.createdAt,
      lastUpdatedAt: Date.now(),
      steps: template.steps,
      numberedImages: template.numberedImages ?? "NoNumberedImages",
      languageCode: template.languageCode ?? "sv",
      mainGrid: {
        elements: mainTemplateElements
      },
      pageHeader: {
        headerVisibility: template.pageHeader.headerVisibility,
        elements: headerElements
      },
      pageFooter: {
        footerVisibility: template.pageFooter.footerVisibility,
        elements: footerElements
      }
    }

    return toSave;
  }

  const saveTemplate = async () => {

    setLoading(true);

    setShowSaveButton(false);

    //console.log("Template to save: " + JSON.stringify(template));

    let toSave = await mapToSavableTemplate();

    if (toSave.steps === undefined) {
      toSave.steps = [];
    }

    const user = JSON.parse(localStorage.getItem('user'));

    var path = "Accounts/" + user.uid + "/Templates";


    //TODO: If no template ID, add template instead of setting it

    try {
      if (templateId === "new") {

        firebaseApp.firestore().collection(path).add(toSave)
          .then((docRef) => {

            //console.log("Document written with ID: ", docRef.id);

            setLoading(false);

            history.push('/admin/templates/' + docRef.id)

          }).catch((error) => {

            setLoading(false);

            alert(error);

          });

      } else {


        firebaseApp.firestore().collection(path).doc(templateId).set(toSave)
          .then(function (docRef) {

            setLoading(false);

          }).catch((error) => {

            setLoading(false);

            alert(error);

          });
      }

    } catch (error) {
      alert(error);
      alert("Error saving to firebase. We are sorry, please contact AppToPDF if the problem remains.");
      setLoading(false);
    }

  }

  //This will save the template as default template
  const saveTemplateAsDefaultTemplate = async () => {

    setLoading(true);

    setShowSaveAsDefaultTemplateButton(false);

    //console.log("Default Template to save: " + JSON.stringify(template));

    let toSave = await mapToSavableTemplate();

    firebaseApp.firestore().collection("defaultTemplates")
      .doc(templateId)
      .set(toSave)
      .then(function () {

        setLoading(false);

        setShowSaveAsDefaultTemplateButton(false);

      }).catch((error) => {

        setLoading(false);

        alert(error);

      });
  }

  const [downloadingCompleted, setdownloadingCompleted] = useState(false);

  useEffect(() => {
    //console.log("Updated in UseEffect: " + JSON.stringify(template));

  }, [template]);

  const createElements = (elements) => {
    var designTimeElements = elements.map((element, i) => {

      var designTimeELement = {
        id: uuidv4(),
        element: element
      };

      if (designTimeELement.element.elementType === "ElementGroup") {
        designTimeELement.element.elements = createElements(designTimeELement.element.elements);
      }

      return designTimeELement;
    });

    return designTimeElements;
  }

  let mapAndSetVariableSpace = (steps) => {
    getCurrentVariableSpaceFromStep(steps)
      .then(data => {
        var designTimeVariableSpace = new DesignTimeVariableSpace(data);
        setVariableSpace(designTimeVariableSpace);
      });
  }

  let orderedSteps = (steps) => {
    if (steps && steps.length > 0) {
      return steps.sort(function (a, b) {
        return a.index - b.index;
      });

    }
  }

  useEffect(() => {

    const user = JSON.parse(localStorage.getItem('user'));

    if (templateId && templateId !== "new") {

      var path = "Accounts/" + user.uid + "/Templates";

      const unregisterFirebaseQuerist = firebaseApp.firestore().collection(path).doc(templateId).get().then((doc) => {
        if (doc.exists) {

          //console.log("Document data:", doc.data());

          var document = doc.data();

          var createdAt = Date.now();

          if (document.createdAt)
            createdAt = document.createdAt;

          var templateInformation = {
            templateName: document.templateName,
            steps: orderedSteps(document.steps),
            createdAt: createdAt,
            numberedImages: document.numberedImages,
            languageCode: document.languageCode ?? "sv",
            mainGrid: {
              elements: createElements(document.mainGrid.elements)
            },
            pageHeader: {
              headerVisibility: document.pageHeader.headerVisibility,
              elements: createElements(document.pageHeader.elements)
            },
            pageFooter: {
              footerVisibility: document.pageFooter.footerVisibility,
              elements: createElements(document.pageFooter.elements)
            }
          };

          setTemplate(templateInformation);

          mapAndSetVariableSpace(templateInformation.steps)

        } else {
          //If maximum number of templates excceeded and the user "forced himself to push add", just return him to subscriptions
          //We need to ahndle that here
        }
        setdownloadingCompleted(true);
        setShowSaveButton(false);


      }).catch((error) => {
        //console.log("Error getting document:", error);
        setdownloadingCompleted(true);
      });

      return unregisterFirebaseQuerist;
    } else {
      setdownloadingCompleted(true);
    }
  }, []);

  const saveButton = () => {

    if (showSaveButton) {
      return (<Button style={{ marginLeft: '10px' }} className="my-4" color="primary" type="button"
        onClick={() => saveTemplate()}>
        SAVE TEMPLATE
      </Button>)
    }
  };

  const saveAsDefaultTemplateButton = () => {
    if (showSaveAsDefaultTemplateButton) {
      return (<Button style={{ marginLeft: '10px' }} className="my-4" color="primary" type="button"
        onClick={() => saveTemplateAsDefaultTemplate()}>
        SAVE TEMPLATE AS DEFAULT TEMPLATE
      </Button>)
    }
  }

  if (loading || !downloadingCompleted) {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <div style={style}>
                <ClipLoader color={"#123abc"} css={loadingCss} loading={"Authenticating"} />
              </div>
            </div>
          </Row>

        </Container>
      </>
    );
  }

  let getElementWarning = (designElement) => {

    let element = { ...designElement.element };

    if (element.elementType === "TextInput") {
      return "Text input: Instead of using it here you should use it in a user step. Then print out the selected value from it with the prefered text element.";
    } else if (element.elementType === "DropDown") {
      return "Drop down: Instead of using it here you should use it in a user step. Then print out the selected value from it with the prefered text element.";
    } else if (element.elementType === "ImageInput" && !element.variable) {
      return "Image input: You would lose the functionality of the image input, it will only be rendered without images. Set a variable to it and it will behave properly.";
    } else if (element.elementType === "CheckBox" && !element.script) {
      return "Check box: You would lose the functionality of the checkbox, it will only be rendered with default value. Set a script value or a variable to it and it will behave properly.";
    } else if (element.elementType === "SignatureInput" && !element.variable) {
      return "Signature: You would lose the functionality of the signature element, it will only be rendered with no signature. Set a variable to it and it will behave properly.";
    } else if (element.elementType === "TableView") {
      if (!element.variable) {
        return "Table view is OK to use, but you need to set a variable to it. Otherwise it will be empty.";
      }
      if (element.checkTable) {
        return "A table with that is a check table won't be checkable in design time, everything int the table will be rendered.";
      }
    } else if (element.elementType === "ElementGroup") {

      return element.elements.map((groupElement) => {
        var warning = getElementWarning(groupElement.element);

        if (warning !== "") {
          return warning;
        }
        return "";
      })
    }

    return "";
  }

  let getPdfWarning = () => {

    if (template.steps) {
      if (template.steps.filter((t) => t.type === "userStep").length > 0) {

        var warnings = [];
        template.mainGrid.elements.map((element) => {

          var warning = getElementWarning(element);

          if (Array.isArray(warning)) {
            warning.map((w) => {
              if (w !== "") {
                warnings.push(
                  <div class="alert alert-warning" role="alert">
                    {w}
                  </div>
                );
              }
            })
          } else {
            if (warning !== "") {
              warnings.push(
                <div class="alert alert-warning" role="alert">
                  {warning}
                </div>
              );
            }
          }

        });

        if (warnings.length > 0) {
          return (
            <>
              <div class="alert alert-warning" role="alert">
                <strong>Warning!</strong> When using user steps to collect data. The design time controls will only be used as rendering controls. The following elements will not behave correctly

              </div>
              {warnings}
            </>

          )
        }
      }
    }

    return null;
  }

  let haveSteps = (template) => {
    if (template.steps) {
      return template.steps.length > 0;
    }
    return false;
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7 bg-secondary" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            {getPdfWarning()}
            <Card className="shadow">
              <CardHeader className="bg-transparent">

                <h3 className="mb-0">PDF Template</h3>

              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="3">
                    <h2>TEMPLATE INFORMATION</h2>
                    <br></br>
                    <Form>
                      <FormGroup>
                        <Label for="templateName">TEMPLATE NAME</Label>
                        <Input type="name" name="name" id="templateName" placeholder="Enter name..." value={template.templateName} onChange={e => updateTemplate("templateName", e.target.value)} />
                      </FormGroup>
                      <br></br>
                      <FormGroup>

                        <Label for="headerOptions">HEADER DISPLAY MODE</Label>
                        <Input type="select" name="headerOptions" id="headerOptions" value={template.pageHeader.headerVisibility} onChange={e => updateTemplate("pageHeader.headerVisibility", e.target.value)}>
                          <option value="ShowOnAllPages">Show on all pages</option>
                          <option value="ShowOnFirstAndLastPage"> Show on first and last page</option>
                          <option value="ShowOnlyOnFirstPage">Show on first page only</option>
                          <option value="ShowOnlyOnLastPage">Show on last page only</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>

                        <Label for="footerOptions">FOOTER DISPLAY MODE</Label>
                        <Input
                          type="select"
                          name="footerOptions"
                          id="footerOptions"
                          value={template.pageFooter.footerVisibility}
                          onChange={e => updateTemplate("pageFooter.footerVisibility", e.target.value)}>
                          <option value="ShowOnAllPages">Show on all pages</option>
                          <option value="ShowOnFirstAndLastPage"> Show on first and last page</option>
                          <option value="ShowOnlyOnFirstPage">Show on first page only</option>
                          <option value="ShowOnlyOnLastPage">Show on last page only</option>
                        </Input>
                      </FormGroup>

                      <FormGroup>
                        <Label for="numberedImages">NUMBERED IMAGES</Label>
                        <Input
                          type="select"
                          name="numberedImages"
                          id="numberedImages"
                          value={template.numberedImages}
                          onChange={e => updateTemplate("numberedImages", e.target.value)}>
                          <option value="NoNumberedImages">Don't use numbered images</option>
                          <option value="NumberedImages"> Use numbered images</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="language">Language</Label>
                        <Input
                          type="select"
                          name="languageCode"
                          id="languageCode"
                          value={template.languageCode}
                          onChange={e => updateTemplate("languageCode", e.target.value)}>
                          <option value="sv">Swedish</option>
                          <option value="en">English</option>
                        </Input>
                      </FormGroup>
                      <br></br>

                    </Form>

                    <br></br>
                    <h2>TEMPLATE ELEMENTS</h2>
                    <Row>

                      <ChoosableTemplateElements
                        headerElements={template.pageHeader.elements}
                        elements={template.mainGrid.elements}
                        footerElements={template.pageFooter.elements}
                        updateTemplate={updateTemplate}
                        haveSteps={haveSteps(template)}
                      />

                    </Row>
                    <br></br>
                    <h2>TEMPLATE STEPS</h2>
                    <Row>

                      <TemplateSteps
                        templateSteps={template.steps}
                        updateTemplate={updateTemplate}
                      />

                    </Row>
                    <br></br>

                    <Button className="my-4" color="primary" type="button" onClick={() => setIsMediaShown(true)}>Show media gallery</Button>

                    <ReactMediaLibraryWrapper
                      imageSelected={(image) => staticImageSelected(image, currentModalItem)}
                      isVisible={isMediaShown}
                      onHidden={() => setIsMediaShown(false)}
                    />

                    <Row>

                      {saveButton()}
                      {/*<br></br>
                      {saveAsDefaultTemplateButton()}*/}
                    </Row>
                  </Col>
                  <Col lg="9">

                    <h3>HEADER</h3>
                    <br></br>

                    <App2PdfGridstack
                      gridElementId="headerGrid"
                      items={template.pageHeader.elements}
                      toggleModal={toggle}
                      setModalElement={setModalElement}
                      onGridItemChanged={updateHeaderElementWidhtHeightAndPosition}
                      onDeleteItem={deleteHeaderGridItem}
                    />

                    <br></br>
                    <h3>MAIN PDF</h3>
                    <br></br>


                    <App2PdfGridstack
                      gridElementId="mainGrid"
                      items={template.mainGrid.elements}
                      toggleModal={toggle}
                      setModalElement={setModalElement}
                      onGridItemChanged={updateElementWidhtHeightAndPosition}
                      onDeleteItem={deleteGridItem}
                    />

                    <br></br>
                    <h3>FOOTER</h3>
                    <br></br>

                    <App2PdfGridstack
                      gridElementId="footerGrid"
                      items={template.pageFooter.elements}
                      toggleModal={toggle}
                      setModalElement={setModalElement}
                      onGridItemChanged={updateFooterlementWidhtHeightAndPosition}
                      onDeleteItem={deleteFooterGridItem}
                    />

                  </Col>

                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <ContextualModal
        isOpen={modalOpen}
        toggle={toggle}
        item={currentModalItem}
        onSaveChanges={updateElements}
        variableSpace={variableSpace}
        showGallery={() => setIsMediaShown(true)}
        currentSubControls={currentSubControls} 
        updateSubControls={updateSubControls}
        previousSteps={template.steps}>

      </ContextualModal>

    </>
  )
}

export default TemplateDesigner;

